.right-aligned {
    justify-content: flex-end;
    font-size: larger;
    margin-right: 60px;
    
}

.box-top {

    margin-right: 20px;
    
    
}

.navtop {
  position: sticky;
  top: 0;
  z-index: 100;
  
  
}



.floatMenu {
  position: absolute;
  right: 0;
  margin-top: 450px; 

}

.floatMenu a {
  color: rgb(206, 206, 206);
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 14px;
  transition: 0.2s;

}

.floatMenu a:hover {
  color: #fff;
  transition: 0.2s;
  text-shadow: 1px 1px 20px rgba(0,0,0,1);
  text-decoration: none
}








@media screen and (max-width:1000px) {
  .floatMenu {
    float: right;
    margin-top: 450px; 
  }
}



.wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -4em;
}

.content {
   padding-top: 0px;
    height: 500px;
    
}


.contentbanner {
  padding-top: 0px;
  padding-bottom: 50px; 
   
}

.contentbanner img {  
  max-width: 100%;  
  width: 100%;
  height: auto;  
  }  



.footer{
    min-height: 93vh;
    height: 4em;
}


.social-container {
    background: #eee;
    padding: 25px 50px;
  }

  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

  a.social:hover {
    transform: translateY(-2px);
  }

  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }




  






.footer {
    background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)), url("http://andreeabunget.co.uk/Img/footer.jpg");
    background-size: cover;
    
}



.first {
    margin-top: 40px;
    margin-bottom: 50px;
    color: rgb(206,206,206);
    font-family: 'Poppins', sans-serif;
}

    .first h4 {
        font-size: 20px;
        letter-spacing: 3px;
        position: relative;
        margin-bottom: 20px;
        font-size: 1.6em;
        color: #fff;
        padding-bottom: 0.5em;
    }

        .first h4::after {
            content: '';
            background: #66c83d;
            width: 20%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
        }

    .first p {
        font-size: 14px;
    }

.second {
    margin-top: 40px;
    margin-bottom: 30px;
    color: rgb(206,206,206);
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.second2 {
    margin-top: 40px;
    margin-bottom: 0px;
    color: rgb(206,206,206);
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.secondcopy {
  margin-top: 0px;
  margin-bottom: 50px;
  color: rgb(206,206,206);
  font-family: 'Poppins', sans-serif;
  text-align: center;
}




:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

.secondlogo {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}



.second h4 {
    font-size: 20px;
    letter-spacing: 3px;
    position: relative;
    margin-bottom: 20px;
    font-size: 1.6em;
    color: #fff;
    padding-bottom: 0.5em;
}

    .second h4::after {
        content: '';
        background: #66c83d;
        width: 20%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 40%;
    }


.second li {
    list-style: none;
    padding-bottom: 30px;
}

.second a, .second2 a {
    color: rgb(206, 206, 206);
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 14px;
    transition: 0.2s;
}

    .second a:hover, .second2 a:hover {
        color: #fff;
        transition: 0.2s;
        text-shadow: 1px 1px 20px rgba(0,0,0,1);
        text-decoration: none

    }



.third {
    margin-top: 40px;
    margin-bottom: 50px;
    color: rgb(206,206,206);
    font-family: 'Poppins', sans-serif;
    text-align: right;
}


    .third h4 {
        font-size: 20px;
        letter-spacing: 3px;
        position: relative;
        margin-bottom: 20px;
        font-size: 1.6em;
        color: #fff;
        padding-bottom: 0.5em;
    }


        .third h4::after {
            content: '';
            background: #66c83d;
            width: 20%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 80%;
        }



    .third li {
        list-style: none;
        padding-bottom: 15px;
    }


    .third a {
        color: rgb(206, 206, 206);
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: bold;
        font-size: 14px;
        transition: 0.2s;
    }


        .third a:hover {
            color: #fff;
            transition: 0.2s;
            text-shadow: 1px 1px 20px rgba(0,0,0,1);
        }


@media screen and (max-width:1000px) {
    .first {
        text-align: center;
    }

        .first h4::after {
            left: 40%;
        }
}

@media screen and (max-width:1000px) {
    .third {
        text-align: center;
    }

        .third h4::after {
            left: 40% !important;
        }
}

.margin {
    margin-left: 20px;
}

.line {
    height:2px;
    background-color:rgb(206,206,206);
    width:100%;
}

.container h1{
    text-align:center;
    margin-top:100px;
    margin-bottom:100px;
}



.copyright {
    font-family:Arial, Helvetica, sans-serif;
    text-align:center;
    padding-top:4px;
    opacity:0.8;
    font-size:15px;
    margin-bottom:0;
  }


  .faq{

    background-color: #eb3223;
    
  }


  .sed {
    color: #fd7a7a;
    background-color: #9a80d8;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

  .Accordion.collapseStyle .Accordion.Body  {
    background-color: cornflowerblue;
  }









  
  
  .accordion {
    max-width: 1000px;
    margin: 2rem auto;
    font-size: 20px;
    color: rgb(206, 206, 206);
  }
  
  .accordiontitle {
    text-align: center;
    font-size: 30px;
    color: rgb(230, 230, 230);
    
  }



  .accordion-title {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border: 0.15rem solid #424242 ;
    margin: 0.5rem;
  }
  
  .accordion-title:hover {
    color: #fff;
            transition: 0.2s;
            text-shadow: 1px 1px 20px rgba(0,0,0,1);
    border: 0.15rem solid rgb(180, 180, 180) ;
   
  }
  
  .accordion-title,
  .accordion-content {
    padding: 1.5rem;
  }
  
  .accordion-content {
    color: rgb(218, 218, 218);
    font-size: 18px;
  }
  
  @media screen and (max-width: 700px) {
    body {
      font-size: 18px;
    }
  
    .accordion {
      width: 90%;
    }
  }





/* Clear floats after the columns */
@media screen and (max-width: 1000px) { 
  .columnt {
   
    float: left !important;
    width: 50% !important;

  }

.columnt:nth-child(2n+1) {
  
  clear: left;
}

}



.t-img{
width:100%; 
}

.t-pos{
  text-align: center;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgb(255, 185, 55); 
  }
.t-dec{
  text-align: center;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
  
  }
  .columnt h2 {
    text-align: center;
    font-size: 18px; 
  }



.rowt {
  width:100%;
  padding: 30px;
  margin: auto;
}

.rowt:after {
  width:100%;
  content: "";
  display: table;
  clear: both;
}


.columnt {
  float: left;
  width: 25%;
  padding: 10px;
  height: auto;
}





  @media screen and (max-width: 1000px) {
    .tablebox{
        width: 100%;
        left: 40% !important;
      }
  
    .table1 {
      width: 90%;
      left: 40% !important;
    }
  }

  .tablebox{
    width: 100%;
    
    margin: auto;
    padding-bottom: 50px;
  }

  


  .table1{
    width:90%;
    
    margin: auto;
  }

  .tabletitle{
      text-align: center;
      color: rgb(230, 230, 230);
      font-size:28px;
      padding-bottom: 1rem;
  }



.tablec1{
  line-height: 1.3rem;
  font-size: 1.0rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
  color: rgb(224, 224, 224);
  width:48.5%;
  margin: auto;
}

.tablec2{
  color: rgb(250, 69, 69);
  width:2%;
  margin: auto;
}


.midline {
  width:1%;
  border-left: 6px solid rgb(250, 69, 69);
  height: 500px;
  
}

.per{
  width:48.5%;
  color: rgb(250, 69, 69);
  font-size:22px;
  padding: 10px;
}

.storyline{
  width:50%;
  margin: auto;
  padding-top: 15px;
  border-bottom: 2px solid rgb(250, 69, 69);
  height: 5px;
}

.story{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
  font-size: 1.1rem;
  line-height: 1.4rem;
  color:rgb(224, 224, 224);
  text-align: justify;
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 100px;
  padding-top: 20px;
}

@media screen and (max-width: 1000px) {
  .story{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 150px;
    padding-top: 20px;
  }
  .storyline{
    width:80%;
    
  }

}




.phase{
  
  font-size: 1.1rem;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 40px;
  padding-top: 80px;
}


.phaset{
  
  font-size: 1.4rem;
  text-decoration: underline rgb(250, 69, 69);
  color:rgb(224, 224, 224);
  text-align: justify;
  padding-top: 20px;

}

.phasec{
  padding-top:5px;
  line-height: 1.3rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
  font-size: 1.0rem;
  color:rgb(224, 224, 224);
  text-align: justify;

}
